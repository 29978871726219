import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { openSimpleInfoModal } from '../store/modals/modalAction'
import { isLightUser } from '../utils/lightUsers'

const useHandleClickOnDisabledLink = () => {
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation()
  const { messages, agencyTitle, loginUrl, registerUrl } = useSelector(
    (state) => state.auth
  )

  const handleClick = (searchMode) => {
    if (isLightUser()) {
      return dispatch(
        openSimpleInfoModal({
          title: t('deal required title'),
          text: t('deal required', { agency: agencyTitle }),
        })
      )
    }

    // translations can be "disabled title <searchmode>" or as fallback "disabled title"
    // translations can be found either in store (agency/customer specific), or as fallback in i18n
    // translations can have -language suffix

    const getTranslation = (key) =>
      messages[key + '-' + i18n.language] ||
      messages[key] ||
      (i18n.exists(key) ? i18n.t(key) : null)

    const title =
      getTranslation(`disabled title ${searchMode}`) ||
      getTranslation(`disabled title`)
    const message =
      getTranslation(`disabled message ${searchMode}`) ||
      getTranslation(`disabled message`)

    console.log(title)

    const buttons = []
    if (registerUrl) {
      buttons.push({
        text: t('become customer'),
        variant: 'outlined',
        onClick: () => window.open(registerUrl, '_parent'),
      })
    }
    if (loginUrl) {
      buttons.push({
        text: t('login'),
        variant: 'contained',
        onClick: () => window.open(loginUrl, '_parent'),
      })
    }

    dispatch(
      openSimpleInfoModal({
        title: title,
        text: message,
        buttons,
      })
    )

    return false
  }

  return handleClick
}

export default useHandleClickOnDisabledLink

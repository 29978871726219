import React, { memo } from 'react'
import {
  Box,
  Typography,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ModalHook from './reusable/modalHook'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { closeAllModals } from '../../store/modals/modalAction'
import { setPolicyExceededReason } from '../../store/checkout/checkoutActions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  weight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  confirmText: {
    maxWidth: '380px',
  },
  btns: {
    borderRadius: theme.spacing(3),
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1, 4),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.1,
  },
  reasonControl: {
    marginTop: theme.spacing(1),
    minWidth: '200px',
  },
}))

const PolicyExceededModal = ({ modalBoolean, clickHandler, allowReasons }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const { policyExceededReason } = useSelector((state) => state.checkout)
  const { policyReasons } = useSelector((state) => state.auth)

  const closeModal = () => {
    return dispatch(closeAllModals())
  }

  const handleReasonSubmit = () => {
    dispatch(closeAllModals())
    setTimeout(() => clickHandler(), 100)
  }

  const handleSetReason = (event) => {
    dispatch(setPolicyExceededReason(event.target.value))
  }

  return (
    <ModalHook
      height={allowReasons ? 360 : 300}
      minHeight={300}
      width={550}
      modalBoolean={modalBoolean}
    >
      <Box className={classes.header}>
        <IconButton
          color="primary"
          className={classes.closeBtn}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.root}>
        <Box>
          <Typography
            gutterBottom
            className={classes.weight}
            variant="h4"
            align="center"
          >
            {allowReasons ? t('policy conditional block') : t('policy block')}
          </Typography>
          <Typography align="center" className={classes.confirmText}>
            {allowReasons
              ? t('policy conditional block text')
              : t('policy block text')}
          </Typography>
        </Box>
        {allowReasons && policyReasons?.length > 0 && (
          <Box>
            <FormControl className={classes.reasonControl}>
              <InputLabel>{t('select reason')}</InputLabel>
              <Select
                value={policyExceededReason}
                onChange={handleSetReason}
                label={t('select reason')}
              >
                {policyReasons.map((reason) => (
                  <MenuItem key={reason.code} value={reason.code}>
                    {i18n.exists(reason.label) ? t(reason.label) : reason.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box mt={6}>
          {allowReasons && (
            <Button
              className={classes.btns}
              color="secondary"
              variant="outlined"
              onClick={closeModal}
            >
              {t('cancel')}
            </Button>
          )}

          <Button
            className={classes.btns}
            color="primary"
            variant="outlined"
            onClick={allowReasons ? handleReasonSubmit : closeModal}
            disabled={allowReasons && !policyExceededReason}
          >
            {t('ok')}
          </Button>
        </Box>
      </Box>
    </ModalHook>
  )
}

export default memo(PolicyExceededModal)
